import classes from './Tabele.module.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { shouldShowColumn, calculateTotal } from './TabelNunta';

const TabelAniversare = (props) => {
    const { answers } = props,
    showNumarParticipanti = shouldShowColumn(answers, 'numarParticipanti');
    return (
        <div className={classes['table-wrapper']}>
            <TableContainer className={classes.table} component={Paper} sx={{ maxHeight: 'inherit' }}>
                <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Nume și prenume</TableCell>
                            <TableCell align="center">Participă</TableCell>
                            {showNumarParticipanti && <TableCell align="center">Număr participanți</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {answers.map((row) => (
                            <TableRow key={row.nume_prenume}>
                                <TableCell>
                                    {row.nume_prenume}
                                </TableCell>
                                <TableCell
                                    align="center">{row.participa}
                                </TableCell>
                                {showNumarParticipanti && (
                                    <TableCell
                                        align="center">{row.numarParticipanti || '-'}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell align="center">Participă</TableCell>
                            {showNumarParticipanti && <TableCell align="center">Număr participanți</TableCell>}
                        </TableRow>
                        <TableRow>
                                <TableCell>
                                    TOTAL
                                </TableCell>
                                <TableCell
                                    align="center">{calculateTotal(answers, 'participa')}
                                </TableCell>
                                {showNumarParticipanti && (
                                    <TableCell
                                        align="center">{calculateTotal(answers, 'numarParticipanti', 'participant')}
                                    </TableCell>
                                )}
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TabelAniversare;