import classes from "./InvitatiileMele.module.scss";

import { useState, useEffect } from "react";
import InvitatiileMeleContext from "../../context/invitatiile-mele-context";
import { useSelector } from "react-redux";
import { fetchUserInvitations } from "../../firebase-invitatii";
import { errorHandler } from "../../Helpers/Helpers";
import Spinner from "../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../Components/UI/AlertMessage/AlertMessage";
import Paper from '@mui/material/Paper';
import Grid from '@mui/system/Unstable_Grid';

import ListaInvitatii from "./ListaInvitatii/ListaInvitatii";
import DetaliiInvitatie from "./DetaliiInvitatie/DetaliiInvitatie";

const InvitatiileMele = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invitationsData, setInvitationsData] = useState(null);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [selectedInvitation, setSelectedInvitation] = useState(null);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const uid = useSelector((state) => state.auth.uid);

    useEffect(() => {
        const fetchInvitations = async () => {
            // Fetch user invitations
            await fetchUserInvitations(uid)
                .then((response) => {
                    response.length > 0 && setInvitationsData(response);
                    response.length === 1 && setSelectedInvitation(response['0']);
                    setAlertError(false);
                })
                .catch((err) => {
                    setAlertError(true);
                    setAlert(errorHandler(err));
                })
                .finally(() => {
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 900);
                });
            // Fetch selected invitation URL
            if (selectedInvitation?.iid) {
                const data = await fetch(
                    `https://us-central1-invitatii-2c400.cloudfunctions.net/retrieveUrl?iid=${selectedInvitation?.iid}`
                )
                    .then(async (response) => {
                        const result = await response.json();
                        return await result;
                    })
                    .catch((err) => {
                        setAlertError(true);
                        setIsLoading(false);
                        setAlert(errorHandler(err));
                    });
                    setSelectedUrl(data.url ? data.url : '');
            }
        };
        fetchInvitations();
    }, [uid, selectedInvitation?.iid]);

    const updateInvitationTitleState = (title) => {
        const newInvitationsData = [];
        invitationsData.forEach((item) => {
            newInvitationsData.push({...item});
        });
        newInvitationsData.forEach((item, index) => {
            if (item.iid === selectedInvitation.iid) {
                newInvitationsData[index].title = title;
            }
        });
        setInvitationsData(newInvitationsData);
        setSelectedInvitation((prevState) => {
            const state = {...prevState};
            state.title = title;
            return state;
        });
    }

    return (
        <InvitatiileMeleContext.Provider value={{
            uid: uid,
            data: invitationsData,
            selectInvitation: setSelectedInvitation,
            selected: selectedInvitation,
            selectedUrl: selectedUrl,
            updateTitleState: updateInvitationTitleState
        }}>
            <div className="page-wrapper">
                <div className="page-content">
                    <h1>Invitațiile mele</h1>
                    {isLoading && <Spinner text='Se încarcă invitațiile...' />}
                    {!isLoading && alertError && (
                        <AlertMessage
                            severity={alert.severity}
                            title={alert.title}
                            description={alert.description}
                            refresh={alert.refresh}
                        />
                    )}
                    {!isLoading && !alertError && (
                        <>
                            {!invitationsData && 
                                <p>Se pare că încă nu ai nicio invitație.</p>
                            }
                            {invitationsData &&
                            <>
                                <ListaInvitatii />
                                <Paper
                                sx={{bgcolor: '#71caed'}}
                                    className={classes['invitatii-wrapper']}
                                    elevation={2}
                                >
                                    <Grid className={classes.grid} container>
                                        <DetaliiInvitatie />
                                    </Grid>
                                </Paper>
                            </>
                            }
                        </>
                    )}
                </div>
            </div>
        </InvitatiileMeleContext.Provider>
    );
};

export default InvitatiileMele;
