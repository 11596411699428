import classes from "./ParticipantiNunta.module.scss";

import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const ParticipantiNunta = (props) => {
    const [participantiArray, setParticipantiArray] = useState([]);
    const [meniuriParticipanti, setMeniuriParticipanti] = useState({});

    const participantiHandler = (event) => {
        const newParticipantsArray = [];
        props.participantiChanged(event.target.value);
        for (var i = 1; i <= event.target.value; i++) {
            newParticipantsArray.push(i);
        }
        setParticipantiArray(newParticipantsArray);
        setMeniuriParticipanti({});
        props.meniuriChanged([]);
    };

    const meniuriAdultiHandler = (event) => {
        const meniuri = { ...meniuriParticipanti };
        meniuri[`${event.target.name}`] = event.target.value;
        setMeniuriParticipanti(meniuri);
        props.meniuriChanged(Object.values(meniuri));
    };

    return (
        <div className={classes["participanti-wrapper"]}>
            <FormControl
                className={classes.participanti}
                fullWidth={!props.notFullWidth}
                component='fieldset'
                error={!!props.errorParticipanti || !!props.errorMeniuri}
            >
                {props.plural && <InputLabel>{props.plural}</InputLabel>}
                <Select
                    id={props.plural}
                    name={props.plural}
                    labelId={`${props.plural}Label`}
                    label={props.plural}
                    value={props.value}
                    onChange={participantiHandler}
                >
                    {props.singular === "copil" && (
                        <MenuItem value={0}>Niciunul</MenuItem>
                    )}
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                </Select>
                {props.errorParticipanti && (
                    <FormHelperText>{props.errorParticipanti}</FormHelperText>
                )}
                {props.errorMeniuri && (
                    <FormHelperText>{props.errorMeniuri}</FormHelperText>
                )}
            </FormControl>
            {participantiArray.map((participant) => {
                return (
                    <FormControl
                        className={classes["item-meniu-wrapper"]}
                        fullWidth={!props.notFullWidth}
                        sx={{ margin: 1 }}
                        key={`meniu${props.plural}${participant}`}
                        error={!!props.errorMeniuri}
                    >
                        <InputLabel
                            id={`meniu${props.plural}Label${participant}`}
                        >
                            {`Meniu ${props.singular} ${participant}`}
                        </InputLabel>
                        <Select
                            id={`meniu${props.plural}${participant}`}
                            name={`meniu${props.plural}${participant}`}
                            labelId={`meniu${props.plural}Label${participant}`}
                            label={`Meniu ${props.singular} ${participant}`}
                            value={
                                meniuriParticipanti[
                                    `meniu${props.plural}${participant}`
                                ] || ""
                            }
                            onChange={meniuriAdultiHandler}
                        >
                            {props.singular === "adult" && (
                                ['Meniu normal', 'Meniu vegetarian'].map((menuItem, index) => (
                                    <MenuItem key={index+1} value={index+1}>
                                        {menuItem}
                                    </MenuItem>
                                ))
                            )}
                            {props.singular === "copil" && (
                                ['Meniu normal copil', 'Meniu vegetarian copil', 'Meniu normal adult', 'Meniu vegetarian adult', 'Fără meniu'].map((menuItem, index) => (
                                    <MenuItem key={index+1} value={index+1}>
                                        {menuItem}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                );
            })}
        </div>
    );
};

export default ParticipantiNunta;
