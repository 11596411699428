import classes from "../../../Form1.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import TextInput from "../../../../../Components/UI/Form/TextInput";
import TextareaInput from "../../../../../Components/UI/Form/TextareaInput";
import DatePickerInput from "../../../../../Components/UI/Form/DatePickerInput";
import DropdownWithOE from "../../../../../Components/UI/Form/DropdownWithOE";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";
import TextInputTooltipLegend from "../../../Components/TextInputTooltipLegend/TextInputTooltipLegend";

const FormSlide1 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [slideImage, setSlideImage] = useState(props.slideData.image);

    const dispatch = useDispatch();

    const onImageChange = (image) => {
        formik.setFieldValue("image", image, !!formik.touched.image);
        setSlideImage(image);
    };

    const onTitluChange = (titlu) => {
        formik.setFieldValue("titlu", titlu, !!formik.touched.titlu);
    };

    const onTextChange = (text) => {
        formik.setFieldValue("text", text, !!formik.touched.text);
    };

    const onNumeSarbatoritChange = (numeSarbatorit) => {
        formik.setFieldValue("numeSarbatorit", numeSarbatorit, !!formik.touched.numeSarbatorit);
    };

    const onNumarSarbatoritChange = (numarSarbatorit) => {
        formik.setFieldValue(
            "numarSarbatorit",
            numarSarbatorit,
            !!formik.touched.numarSarbatorit
        );
    };

    const onTipEvenimentChange = (tipEveniment) => {
        formik.setFieldValue(
            "tipEveniment",
            tipEveniment,
            !!formik.touched.tipEveniment
        );
    };

    const onDataEvenimentChange = (dataEveniment) => {
        formik.setFieldValue(
            "dataEveniment",
            dayjs(dataEveniment),
            !!formik.touched.dataEveniment
        );
    };

    const onDataConfirmareChange = (dataConfirmare) => {
        formik.setFieldValue(
            "dataConfirmare",
            dayjs(dataConfirmare),
            !!formik.touched.dataConfirmare
        );
    };

    const locallySaveSlide1 = (labelName, dataSlide1, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide1), { expires: 1 / 24 * hoursToExpire });
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            iid: props.slideData.iid,
            image: props.slideData.image,
            titlu: props.slideData.titlu,
            text: props.slideData.text,
            numeSarbatorit: props.slideData.numeSarbatorit,
            numarSarbatorit: props.slideData.numarSarbatorit,
            tipEveniment: props.slideData.tipEveniment,
            dataEveniment: dayjs(props.slideData.dataEveniment),
            dataConfirmare: dayjs(props.slideData.dataConfirmare),
        },
        validationSchema: Yup.object().shape({
            image: Yup.object(),
            titlu: Yup.string(),
            text: Yup.string().required("Textul invitației este obligatoriu!"),
            numeSarbatorit: Yup.string().required("Numele mirelui este obligatoriu!"),
            numarSarbatorit: Yup.string()
                .required("Numărul de telefon al mirelui este obligatoriu!")
                .matches(/^07[0-9]{8}$/, "Număr de telefon invalid!"),
            tipEveniment: Yup.string().required("Tipul evenimentului este obligatoriu!"),
            dataEveniment: Yup.date().required("Introduceți data nunții"),
            dataConfirmare: Yup.date()
                .required("Introduceți data de confirmare a prezenței")
                .max(
                    Yup.ref("dataEveniment"),
                    "Data confirmării trebuie să fie înainte de data evenimentului!"
                ),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide1Data = {
                iid: values.iid,
                image: {
                    value: slideImage.value,
                    url: slideImage.url
                        ? slideImage.url
                        : `/Media/Images/Modele/Aniversare/${slideImage.value}`,
                },
                titlu: values.titlu,
                text: values.text,
                numeSarbatorit: values.numeSarbatorit,
                numarSarbatorit: values.numarSarbatorit,
                tipEveniment: values.tipEveniment,
                dataEveniment: values.dataEveniment,
                dataConfirmare: dayjs(values.dataConfirmare),
            };
            props.setEventDate(values.dataEveniment);
            props.setSlideData(slide1Data);
            if (props.editMode) {
                const invData = {
                    a1s1: JSON.parse(Cookies.get(`${props.invId}_a1s1`)),
                    a1s2: JSON.parse(Cookies.get(`${props.invId}_a1s2`)),
                    a1s3: JSON.parse(Cookies.get(`${props.invId}_a1s3`)),
                };
                locallySaveSlide1(`${invData.a1s1.iid}_a1s1`, slide1Data, 1);
                invData.a1s1 = JSON.parse(Cookies.get(`${invData.a1s1.iid}_a1s1`));
                await saveOrUpdateInvitationData(
                    props.uid,
                    'a1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide1('a1s1', slide1Data, 24);
                setIsSaving(false);
                props.slideUpdated(true);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: `Modificările au fost salvate cu succes!`,
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <ImageRadios
                        default={props.slideData.image}
                        text='Poză stânga, vizibilă doar pe ecrane mari (PC):'
                        labels={[
                            "a1s1_1",
                            "a1s1_2",
                            "a1s1_3",
                            "a1s1_4",
                            "a1s1_5",
                            "a1s1_6",
                            "a1s1_7",
                            "a1s1_8",
                            "a1s1_9",
                            "a1s1_10",
                            "a1s1_11",
                            "a1s1_12",
                            "a1s1_13",
                            "a1s1_14",
                            "a1s1_15",
                            "a1s1_16",
                            "a1s1_17",
                            "a1s1_18",
                            "a1s1_19",
                        ]}
                        addItem={{
                            imageId: formik.values.iid,
                            type: "a1s1_99",
                            path: "Aniversare",
                            url:
                                props.slideData.image.value === "a1s1_99" &&
                                props.slideData.image.url,
                        }}
                        path='Aniversare'
                        changeHandler={onImageChange}
                    />
                    <TextInput
                        label='Titlu (opțional)'
                        value={formik.values.titlu}
                        changeHandler={onTitluChange}
                        error={formik.errors.titlu}
                    />
                    <TextareaInput
                        label={<TextInputTooltipLegend />}
                        value={formik.values.text}
                        placeholder='Text'
                        changeHandler={onTextChange}
                        error={formik.errors.text}
                    />
                    <TextInput
                        label='Sărbătorit (organizator)'
                        value={formik.values.numeSarbatorit}
                        changeHandler={onNumeSarbatoritChange}
                        error={formik.errors.numeSarbatorit}
                    />
                    <TextInput
                        label='Număr telefon organizator'
                        value={formik.values.numarSarbatorit}
                        placeholder='ex: 0711222333'
                        changeHandler={onNumarSarbatoritChange}
                        error={formik.errors.numarSarbatorit}
                    />
                    <DropdownWithOE
                        dropdownValues={['party', 'petrecere', 'Christmas Party', 'Easter Party', 'bere', 'grătar', 'un pahar de vin', 'petrecerea de Revelion']}
                        label='Tipul evenimentului'
                        value={formik.values.tipEveniment}
                        placeholder={`${formik.values.numeSarbatorit || 'Tudor'} te invită la PARTY`}
                        changeHandler={onTipEvenimentChange}
                        error={formik.errors.tipEveniment}
                    />
                    {formik.errors.dataConfirmare && (
                        <div className={classes["line-wrapper"]}>
                            <span className={classes.invalid}>
                                {formik.errors.dataConfirmare}
                            </span>
                        </div>
                    )}
                    <div className={classes["line-wrapper"]}>
                        <DatePickerInput
                            notFullWidth
                            disablePast
                            label='Data evenimentului'
                            value={formik.values.dataEveniment}
                            changeHandler={onDataEvenimentChange}
                            error={formik.errors.dataEveniment}
                        />
                        <DatePickerInput
                            notFullWidth
                            disablePast
                            label='Data confirmării'
                            value={formik.values.dataConfirmare}
                            changeHandler={onDataConfirmareChange}
                            error={formik.errors.dataConfirmare}
                        />
                    </div>
                    <Button
                        id={classes.submitButton}
                        type='submit'
                        variant='contained'
                    >
                        Actualizare
                    </Button>
                </form>
            )}
        </div>
    );
};

export default FormSlide1;
