import * as React from "react";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const Radios = (props) => {
    const changehandler = (event) => {
        props.changeHandler(event.target.value);
    };
    return (
        <FormControl error={!!props.error}>
            <FormLabel>{props.label}</FormLabel>
            {props.error && <FormHelperText>{props.error}</FormHelperText>}
            <RadioGroup
                row={props.row}
                defaultValue={props.default}
                onChange={changehandler}
            >
                {props.options.map((option) => {
                    return (
                        <FormControlLabel
                            key={option.toLowerCase()}
                            value={option}
                            label={option}
                            sx={{
                                color: !!props.error ? "#d32f2f" : "black",
                            }}
                            control={
                                <Radio
                                    sx={{
                                        color: !!props.error
                                            ? "#d32f2f"
                                            : "black",
                                    }}
                                />
                            }
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default Radios;
