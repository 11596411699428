import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const TextInputTooltipLegend = () => {
    const tooltiptext = <p>
        Pentru a stiliza textul puteți folosi următoarele:
        <ul>
            <li>
                <strong>&lt;b&gt;</strong> - pentru text îngroșat:<br/>
                Sunt &lt;b&gt;Liviu&lt;/b&gt; și vă invit la... =&gt;<br/>
                Sunt <strong>Liviu</strong> și vă invit la...
            </li>
            <li>
                <strong>&lt;i&gt;</strong> - pentru text înclinat:<br/>
                Sunt &lt;i&gt;Liviu&lt;/i&gt; și vă invit la... =&gt;<br/>
                Sunt <i>Liviu</i> și vă invit la...
            </li>
            <li>
                <strong>&lt;br&gt;</strong> - pentru a scrie pe un rând nou:<br/>
                Ne vedem acolo.&lt;br&gt; Te aștept! =&gt;<br/>
                Ne vedem acolo.<br/> Te aștept!
            </li>
        </ul>
    </p>;
    return (
        <>
            Text&nbsp;
            <Tooltip TransitionComponent={Zoom} title={tooltiptext} arrow>
                <i className="far fa-question-circle"></i>
            </Tooltip>
        </>
    );
}

export default TextInputTooltipLegend;